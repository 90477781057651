import React from "react";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Carousels from "./Carousels";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";

function Home() {
  const { t } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [partner, setPartner] = useState([]);

  useEffect(() => {
    const fetchAllActivities = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/activities");
        setActivities(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchAllActivities();
  }, []);

  useEffect(() => {
    const fetchAllPartner = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/partner");
        setPartner(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchAllPartner();
  }, []);

  useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  const href = window.location.href.substring(
    window.location.href.lastIndexOf('#') + 1,
  );
  if (window.location.href.lastIndexOf('#') > 0) {
    document.getElementById(href)?.scrollIntoView();
  }
  });

  return (
    <>
      <Header />
      <Carousels />
      <main id="main">

          <section id="pilar_usaha" className="clients section-bg" style={{marginTop:"-30px"}}>
            <div className="container" >
              <div className="section-title" style={{marginBottom:"-30px"}} >
                <h2>{t("pilar_usaha.home")}</h2>
              </div>
              <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up" >
                <div className="row justify-content-center align-items-center">
                
                  <div className="col-lg-2 bg-image imageServices" style={{marginBottom:"30px", marginLeft: "25px" }}>
                  <Link to="/financial_services" target="_blank">
                  {/* <div class="member" data-aos="fade-up" data-aos-delay="100">
                      <div class="member-img">
                      <div class="social">
                          <h5>Financial Services</h5>
                      </div> */}
                      <img src="assets/img/pilar_usaha/Financial.png" className="img-fluid center-block" alt="Nusumma" />
                  {/* </div>
                  </div> */}
                    </Link>
                  </div>

                  <div className="col-lg-2 bg-image" style={{marginBottom:"30px", marginLeft: "25px" }}>
                    <Link to="/digital_services" target="_blank">
                      <img src="assets/img/pilar_usaha/Digital.png" className="img-fluid center-block" alt="" />
                    </Link>
                  </div>
                  <div className="col-lg-2 bg-image" style={{marginBottom:"30px", marginLeft: "25px" }}>
                    <Link to="/professional_services" target="_blank">
                      <img src="assets/img/pilar_usaha/Professional.png" className="img-fluid center-block" alt=""  />
                    </Link>
                  </div>
                   <div className="col-lg-2 bg-image" style={{marginBottom:"30px", marginLeft: "25px" }}>
                    <Link to="/sme_incubation" target="_blank">
                      <img src="assets/img/pilar_usaha/SME.png" className="img-fluid center-block" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

        <section id="testimonials" className="testimonials section-bg" style={{marginTop:"-80px", marginBottom:"-80px"}}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom:"-20px"}}>
              <h2>{t("our_activities.home")}</h2>
            </div>
 
            <div className="row justify-content-center">
            <div className="col-md-10">
            <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">
              {activities.map((activities) => (
                <div key={activities.id_activities} className="swiper-slide">
                  <div className="testimonial-item ">
                    <div className="bg-image justify-content-center">
                      <img src={"/assets/img/kegiatan/"+activities.picture} className="img-fluid testimonial-img" alt="" />
                    </div>
                  </div>
                  <h6 style={{marginLeft: "5px"}}>{activities.description}</h6>
                </div>
                ))}
                </div>
                </div>

                
              </div>
            </div>
          </div>
        </section>

        

        <section id="clients" className="clients section-bg" style={{marginTop:"10px"}}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{marginBottom:"-20px"}}>
              <h2>{t("visi_misi.home")}</h2>
            </div>
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center">
                <div className="col-md-3 bg-image" style={{ marginBottom: "40px", marginLeft: "25px", fontSize:"16px" }}>
                  <img src="assets/img/visi_misi/Capital_Investment.png" className="img-fluid center-block" alt="" />
                  <p style={{ textAlign: "center" }}>{t("capital_investment.mission")}</p>
                </div>
                <div className="col-md-3 bg-image" style={{ marginBottom: "40px", marginLeft: "25px" }}>
                  <img src="assets/img/visi_misi/Capacity_Building.png" className="img-fluid center-block" alt="" />
                  <p style={{ textAlign: "center", fontSize:"16px" }}>
                    {t("capacity_building.mission")}
                  </p>
                </div>
                <div className="col-md-3 bg-image" style={{ marginBottom: "40px", marginLeft: "25px", fontSize:"16px" }}>
                  <img src="assets/img/visi_misi/Community_Empowerment.png" className="img-fluid center-block" alt="" />
                  <p style={{ textAlign: "center" }}>
                    {t("community_empowerment.mission")}
                  </p>
                </div>
              </div>

              <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                <div className="col-md-8 bg-image">
                  <Link to="/tentang">
                    <img src="assets/img/Our_Team.png" className="img-fluid center-block" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg" style={{marginTop:"-50px"}}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{marginTop:"-20px"}}>
              <h2>Partner</h2>
            </div>

            <h6 style={{ textAlign: "center", marginTop: "-30px" }}>{t("partner.desc")}</h6>
            <br></br>
            <br></br>
            <br></br>
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ textAlign: "center", marginTop: "-50px" }}>
              <div className="col-lg-3"></div>
              <div className="row col-lg-6 justify-content-center align-items-center">
              {partner.map((partner) => (
                <div key={partner.id_partner} className="col-lg-6 col-md-4 bg-image" >
                  <div className="client-logo">
                  <Link to="#" >
                    <img src={"assets/img/partner/"+partner.picture} className="img-fluid" alt="" />
                  </Link>
                  </div>
                </div>
                ))}
                </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
export default Home;
