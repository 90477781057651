import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "moment/locale/id";
import { Nav } from "react-bootstrap";
moment.locale("id");

const Berita = () => {
  const { t } = useTranslation();
  const [berita, setBerita] = useState([]);
  const [berita_headline, setBeritaHeadline] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);

  useEffect(() => {
    const fetchHeadlineBerita = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/berita_headline");
        setBeritaHeadline(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchHeadlineBerita();
  }, []);

  useEffect(() => {
    setLimit(4);
    const getBerita = async () => {
      const response = await axios.get(`http://api.web-dev.nbagroup.co.id/berita?page=${page}&limit=${limit}`);
      setBerita(response.data.result);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    };
    getBerita();
  }, [page, limit]);

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  return (
    <>
      <Header />

      {/* <<<<<<< HEAD
      <main id="main">
        <div className="row justify-content-center">
          <div className="col-md-12 bg-image image">
            <img src="assets/img/berita/berita.png" className="img-fluid centerImage" alt="" />
          </div>
        </div>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{t("berita.header")}</h2>
            </div>
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center">
                {berita_headline.map((berita) => (
                  <div className="col-md-8 bg-image">
                    <Link to={`/detailberita/${berita.id}`}>
                      <img src={berita.picture} className="img-fluid imageBeritaHeadline" alt="" />
                      <h3>{berita.title}</h3>
                      <h6>{berita.author}, 11 November 2022</h6>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="row justify-content-center" style={{ marginTop: "50px" }}>
                {beritas.map((berita) => (
                  <div className="col-md-4 bg-image" style={{ marginBottom: "40px", marginLeft: "20px" }}>
                    <Link to={`/detailberita/${berita.id}`}>
                      <img src={berita.picture} className="img-fluid imageBerita" alt="" />
                      <h4>{berita.title}</h4>
                      <h6>{berita.author}, 6 November 2022</h6>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
======= */}

      <main id="main">
        <div className="col-md-12 bg-image image">
          <img src="assets/img/berita/berita.png" className="img-fluid imageContent" alt="" />
        </div>

        <section id="clients" className="clients section-bg">
          <div className="container" >
            <div className="section-title" style={{marginTop:"-30px"}}>
              <h2>{t("berita.header")}</h2>
            </div>
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center">
                {berita_headline.map((berita) => (
                  <div key={berita.id} className="col-md-8 bg-image">
                    <Link to={`/detailberita/${berita.id}/${berita.link}`}>
                      <img src={"/assets/img/berita/" + berita.picture} className="img-fluid imageBeritaHeadline" alt="" />
                      <h3>{berita.title}</h3>
                      <h6>
                        {berita.author}, {moment(berita.createdAt).format("DD MMMM YYYY")}
                      </h6>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="row justify-content-center" style={{ marginTop: "50px" }}>
                {berita.map((berita) => (
                  <div key={berita.id} className="col-md-4 bg-image" style={{ marginBottom: "40px", marginLeft: "20px" }}>
                    <Link to={`/detailberita/${berita.id}/${berita.link}`}>
                      <img src={"/assets/img/berita/" + berita.picture} className="img-fluid imageBerita" alt="" />
                      <h4>{berita.title}</h4>
                      <h6>
                        {berita.author}, {moment(berita.createdAt).format("DD MMMM YYYY")}
                      </h6>
                    </Link>
                  </div>
                ))}

                <ReactPaginate
                  previousLabel={"< Prev"}
                  nextLabel={"Next >"}
                  pageCount={Math.min(4, pages)}
                  onPageChange={changePage}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active activePage"}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
              }}
            >
              <Nav className="pagination is-centered" key={rows} role="navigation" aria-label="pagination"></Nav>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Berita;
