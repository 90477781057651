import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Carousels2 from "./Carousels2";
import { ExternalLink } from "react-external-link";
import { useEffect, useState } from "react";
import axios from "axios";

const Nusumma = () => {
  const [assets, setAssets] = useState("");
  const [office, setOffice] = useState("");
  const [customer, setCustomer] = useState("");
  const [product, setProduct] = useState("");
  const [npl, setNpl] = useState("");
  const [roa, setRoa] = useState("");

  useEffect(() => {
    const getFinancial = async () => {
    const response = await axios.get(`http://api.web-dev.nbagroup.co.id/financial/`);
    setAssets(response.data.assets);
    setOffice(response.data.office);
    setCustomer(response.data.customer);
    setProduct(response.data.product);
    setNpl(response.data.npl);
    setRoa(response.data.roa);
    }
    getFinancial();
  }, []);

  return (
    <>
      <Header />

      <main id="main">
      <div className="col-md-12 bg-image image"   >
      <img src="assets/img/Nusumma.png" className="img-fluid imageContent" alt="" />
      </div>

        <section id="clients" className="clients section-bg">
          <div className="container" >
            <div className="row justify-content-center no-gutters clients-wrap clearfix"  style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image " style={{marginTop:"-30px"}}>
                <h3 style={{ textAlign: "left" }}>32 Tahun Pengalaman Kami Dalam Membantu Masyarakat Indonesia</h3>
              </div>
              <div className="col-md-10 bg-image " data-aos="fade-up">
                <p style={{ textAlign: "justify", fontSize: "16px" }}>
                  Bank Nusumma didirikan berdasarkan MoU yang ditanda tangani pada tanggal 1 Juni 1990 antara pihak Nahdatul Ulama yang diwakili oleh Alm. K.H. Abdurrahman Wahid dengan Bank Summa.
                  Sejak tahun 2018 sampai dengan sekarang, kepemilikan Bank Perkreditan Rakyat Nusumma Group telah beralih ke Nusantara Bina Artha sebagai Pemegang Saham Pengendali (PSP).
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-90px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }} >
              <h2>Visi</h2>
            </div>
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "1px" }}>
              <div className="col-md-10 bg-image ">
                <h4 style={{ textAlign: "center"}}>Menjadi Bank yang Keren dan Juara</h4>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-70px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Misi</h2>
            </div>
            
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image ">
                <h5 style={{ textAlign: "justify", marginTop: "-25px", fontWeight: "normal" }}>
                  1.Menjadi Bank yang dikenal dekat dengan Masyarakat dikarenakan layanan yang innovatif, mulia, dan terpercaya.
                </h5>
                <br />
                <br />
                <h5 style={{ textAlign: "justify", marginTop: "-25px", fontWeight: "normal" }}>2.Konsisten manjadi Bank yang sehat, untuk dapat tumbuh dan berkembang bersama Masyarakat.</h5>
                <br />
                <br />
                <h5 style={{ textAlign: "justify", marginTop: "-25px", fontWeight: "normal" }}>
                  3.Memiliki indeks kebahagiaan SDM dan Organisasi yang tinggi, sehingga dapat terus memberikan dampak positif kepada Masyarakat. Sekaligus berkontribusi terhadap perekonomian
                  Indonesia.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Budaya Kerja BPR Nusumma Group</h2>
            </div>
            <div className="row justify-content-center">
            <div className="col-md-8 bg-image ">
            <Carousels2 />
            </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Peta Pesebaran</h2>
            </div>

            <div className="row justify-content-center">
            <div className="col-md-8 bg-image ">
              <img src="assets/img/Peta.png" className="img-fluid centerImage" alt="" style={{height:"480px"}} />
            </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Overview Nusumma</h2>
            </div>

            <div className="row justify-content-center ">
            <div className="col-md-8" >
            <div className="row ">
            <div className="col-md-4" >
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/assets.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> Rp. {assets} Milliar + Total Assets</h5>
            </div>
            </div>
            </div>
            

            <div className="col-md-4" >
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/kantor.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> {office} Jaringan Kantor</h5>
            </div>
            </div>
            </div>

            <div className="col-md-4" >
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/nasabah.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> {customer} Ribu + Nasabah</h5>
            </div>
            </div>
            </div>

            <div className="col-md-4" style={{marginTop:"80px"}}>
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/produk.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> {product} + Produk</h5>
            </div>
            </div>
            </div>

            <div className="col-md-4" style={{marginTop:"80px"}}>
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/npl.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> NPL {npl}%</h5>
            </div>
            </div>
            </div>

            <div className="col-md-4" style={{marginTop:"80px"}} >
            <div className="row justify-content-center align-items-center">
            <div className="col-md-4">
              <img src="assets/img/services/roa.png" className="img-fluid" alt="" style={{width:"140px", height:"75px"}} />
            </div>
            <div className="col-md-8">
              <h5> ROA {roa}%</h5>
            </div>
            </div>
            </div>

            </div>
            </div>

            
          
            </div>

          </div>
        </section>

        <section id="testimonial" className="testimonial section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Jajaran Pengurus</h2>
            </div>

            <div className="row justify-content-center">
            <div className="col-md-8">
            <div className="testimonial-slider swiper" data-aos="fade-up" data-aos-delay="100">
            
              <div className="swiper-wrapper">
                <div className="m-mx-n2 swiper-slide">
                  <div className="testimonial-item ">
                    <div className="bg-image ">
                      <img src="assets/img/Direksi1.png" className="img-fluid testimonial-img " alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="m-mx-n2 swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi2.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi3.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi4.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi5.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi6.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi7.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="bg-image">
                      <img src="assets/img/Direksi8.png" className="img-fluid testimonial-img" alt="" style={{ width: "80%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg" style={{ marginTop: "-60px" }}>
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "-30px" }}>
              <h2>Hubungi Kami</h2>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8">
                <p className="text-center">Informasi lebih lanjut kunjungi <ExternalLink href="https://nusumma.co.id" target="_blank"><b>www.nusumma.co.id</b></ExternalLink></p>
                </div>
            </div>
            
    
            {/* <div class="position-absolute top-100 start-50 translate-middle">
              <img src="assets/img/Link.png" className="img-fluid center-block" alt="" />
            </div> */}
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
};
export default Nusumma;
