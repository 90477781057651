import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";

function Carousels() {
  const [carousel, setCarousel] = useState([]);

  useEffect(() => {
    const fetchAllCarousel = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/carousel");
        setCarousel(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    
    fetchAllCarousel();
  }, []);
  return (
    <Carousel variant="light" interval={8000}>
    {carousel.map((carousel) => ( 
      <Carousel.Item key={carousel.id_carousel}>
      <div  className="image">
        <img className="d-block w-100" src={"assets/img/slide/"+carousel.picture} alt="First slide" />
      </div>
      </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Carousels;
