import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    const Auth = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://api.web-dev.nbagroup.co.id/login', {
                username: username,
                password: password
            });
            navigate("/admin/home");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }


    useEffect(() => {
       const refreshToken = async () => {
        try {
            const response = await axios.get('http://api.web-dev.nbagroup.co.id/token');
            const decoded = jwt_decode(response.data.accessToken);
            if(decoded){navigate("/admin/");}
        } catch (error) {
            if (error.response) {
                navigate("/login");
            }
        }
    }
    refreshToken();
    }, [navigate]);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody >
                    <img src="/assets/img/NBA-2.png" className="img-fluid" alt="" />
                  <CForm onSubmit={Auth} style={{marginTop: "10px"}}>
                    {/* <h1 className="text-center" >Login</h1> */}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <i className="ri-user-2-line"></i>
                      </CInputGroupText>
                      <CFormInput 
                      placeholder="Username" 
                      autoComplete="username" 
                      value={username} 
                      onChange={(e) => setUsername(e.target.value)} 
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <i className="ri-lock-2-line"></i>
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                    </CRow>
                    <p className="has-text-centered">{msg}</p>
                  </CForm>
                </CCardBody>
              </CCard>
              
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
