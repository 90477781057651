import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu, cilAccountLogout } from '@coreui/icons'
import {useState, useEffect} from "react"
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios';
import jwt_decode from "jwt-decode";

function AppHeader() {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)

    const [name, setName] = useState('');
    const [expire, setExpire] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
    });

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://api.web-dev.nbagroup.co.id/token');
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.username);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('http://api.web-dev.nbagroup.co.id/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.username);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const Logout = async () => {
        try {
            await axios.delete('http://api.web-dev.nbagroup.co.id/logout');
            navigate("/login");
        } catch (error) {
            console.log(error);
        }
    }


  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <img className='sidebar-brand-full' src='../../../assets/img/NBA-2.png' alt='Logo' height={35}/>
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
        <CNavItem style={{marginRight: "10px"}}>
              Welcome {name}
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-5">
          <CNavItem >
            <Link to="#" onClick={Logout}>
              <CIcon icon={cilAccountLogout} size="lg"  />
              </Link>
          </CNavItem>
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      
    </CHeader>
  )
}

export default AppHeader
