import React from "react";
import { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import axios from "axios";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const Karir = () => {
  const { t } = useTranslation();
  const [karirs, setKarir] = useState([]);

  useEffect(() => {
    const fetchAllKarir = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/karir");
        setKarir(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllKarir();
  }, []);

  return (
    <>
      <Header />

      <main id="main">
        <div className="col-md-12 bg-image image">
          <img src="assets/img/karir/karir.png" className="img-fluid imageContent" alt="" />
        </div>

        <section id="services" className="services section-bg">
          <div className="container" >
            <div className="section-title" style={{marginTop:"-30px"}}>
              <h2>{t("karir.header")}</h2>
            </div>

            <div className="row justify-content-center align-items-center no-gutters clients-wrap clearfix" data-aos="fade-up">
              {karirs.map((karir) => (
                <div key={karir.id_karir} className="col-md-3 mt-4 " data-aos="zoom-in" data-aos-delay="100" style={{ marginBottom: "40px", marginLeft: "50px", marginRight: "50px" }}>
                  <Link to={`/detailkarir/${karir.id_karir}/${karir.link}`}>
                    <div className="icon-box">
                      <h4>{karir.position}</h4>
                      <p className="warnaKarir" style={{ fontSize: "16px" }}>
                        <i className="ri-map-pin-line"></i> {karir.placement}
                      </p>
                      <p className="warnaKarir" style={{ fontSize: "16px" }}>
                        <i className="ri-money-dollar-box-line"></i> IDR {karir.salary}
                      </p>
                      <p className="warnaKarir" style={{ fontSize: "16px" }}>
                        <i className="ri-briefcase-3-line"></i> {karir.experience}
                      </p>
                      <br></br>
                      <p className="warnaKarirHijau" style={{ fontSize: "16px" }}>
                        Diperbarui {moment(karir.date).startOf("hour").fromNow()}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Karir;
