import React from "react";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ExternalLink } from 'react-external-link';

function Tentang() {
  const { t } = useTranslation();
  const [ceo, setCeo] = useState([]);
  const [coo, setCoo] = useState([]);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetchCeo = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/ceo");
        setCeo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchCoo = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/coo");
        setCoo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    const fetchTeam = async () => {
      try {
        const res = await axios.get("http://api.web-dev.nbagroup.co.id/team");
        setTeam(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCeo();
    fetchCoo();
    fetchTeam();
  }, []);



  return (
    <>
      <Header />


      <main id="main">
          <div className="col-md-12">
            <img src="assets/img/team/Our_Team_Page1.png" className="img-fluid imageContent" alt=""  />
          </div>

      <section id="clients" className="clients section-bg">
      <div className="container" >
        <div className="section-title" style={{marginTop:"-30px", marginBottom:"-30px"}}>
          <h2>{t("tentang_kami")}</h2>
        </div>
        <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{marginTop: "30px"}}>
          <div className="col-md-8 bg-image">
        <p style={{textAlign: "center", marginTop:"-25px", fontSize:"25px"}}>{t("tentang.desc")}</p>
        </div>
      </div>

        <div className="row justify-content-center align-items-center" style={{marginTop: "120px"}}>
         <div className="section-title" style={{marginTop:"-30px"}}>
          <h2>Tim Kami</h2>
        </div>
         {ceo.map((ceo) => (
          <div key={ceo.id_team} className="col col-lg-2 bg-image" style={{textAlign: "center", marginBottom:"40px", marginLeft:"100px", marginRight:"100px"}}>
            <ExternalLink href={ceo.linkedin} target="_blank">
            <img src={"/assets/img/team/"+ceo.picture} className="img-fluid" alt="" />
            <h5 style={{marginTop:"15px"}}>{ceo.name}</h5>
            <h6>{ceo.position}</h6>
            </ExternalLink>
          </div>
          ))}
           {coo.map((coo) => (
          <div key={coo.id_team} className="col col-lg-2 bg-image" style={{textAlign: "center", marginBottom:"40px", marginLeft:"100px", marginRight:"100px"}}>
            <ExternalLink href={coo.linkedin} target="_blank">
            <img src={"/assets/img/team/"+coo.picture} className="img-fluid" alt="" />
            <h5 style={{marginTop:"15px"}}>{coo.name}</h5>
            <h6>{coo.position}</h6>
            </ExternalLink>
          </div>
          ))}
        </div>
      </div>
        </section>
        
        <section id="team" className="team section-bg" style={{marginTop: "-150px"}}>
        <div className="row justify-content-center no-gutters team-wrap clearfix" data-aos="fade-up" style={{marginTop: "30px"}}>
          {team.map((team) => (
          <div key={team.id_team} className="col col-lg-2 bg-image" style={{textAlign: "center", marginBottom:"15px", marginLeft:"30px", marginRight:"30px"}}>
            <div className="team-logo">
            <ExternalLink href={team.linkedin} target="_blank">
            <img src={"/assets/img/team/"+team.picture} className="img-fluid" alt="" />
            <h5 style={{marginTop:"15px"}}>{team.name}</h5>
            <h6>{team.position}</h6>
            </ExternalLink>
            </div>
          </div>
         ))}
      </div>
    </section>

      </main>

      <Footer />
    </>
  );
}
export default Tentang;
