import React from 'react';
import {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from './Header';
import Footer from './Footer';

const DetailKarir = () => {
  const [detailKarir, setDetailKarir] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchDetailKarir = async () => {
      try {
        const res = await axios.get(`http://api.web-dev.nbagroup.co.id/karir/${id}`);
        setDetailKarir(res.data);
      } catch (err) {
      }
    };
    fetchDetailKarir();

  });



  return (
<>
  <Header/>



  <main id="main">
    
      <div className='col-md-12 bg-image image'  >
        <img src="/assets/img/karir/karir.png" className="img-fluid imageContent" alt="" />
      </div>

    <section id="clients" className="clients section-bg">
      <div className="container" data-aos="fade-up">
        
        <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up">
        {detailKarir.map((karir) => (
        <div className='col-md-10' key={karir.id_karir}>
        <h3 style={{marginTop: "10px"}}>{karir.position}</h3>
        <h6 style={{marginTop: "40px"}}>Skills Wajib</h6>

        <div className='skillsWajib' dangerouslySetInnerHTML={ { __html: karir.skills } }>
        </div>

        <h6 style={{marginTop: "40px"}}>Deskripsi Pekerjaan</h6>

        <div className='deskripsiPekerjaan' dangerouslySetInnerHTML={ { __html: karir.job_description } } >
        </div>

        <h6 style={{marginTop: "40px"}}>Syarat Pekerjaan</h6>

        <div className='syaratPekerjaan' dangerouslySetInnerHTML={ { __html: karir.requirements } }>
        </div>
        </div>
          ))}
        </div>
        </div>

    </section>

  </main>

  <Footer/>
</>
  );
}
export default DetailKarir;