import React from 'react';
import {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from './Header';
import Footer from './Footer';
import moment from "moment";
import 'moment/locale/id' 
moment.locale('id')


const DetailBerita = () => {
  const [detailBerita, setDetailBerita] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchDetailBerita = async () => {
      try {
        const res = await axios.get(`http://api.web-dev.nbagroup.co.id/berita/${id}`);
        setDetailBerita(res.data);
      } catch (err) {
      }
    };
    fetchDetailBerita();

  });

 

  

  return (
<>
  <Header/>
          {detailBerita.map((berita) => (
  <main id="main" key={berita.id}>
      <div className='col-md-12 bg-image image'  >
        <img src={"/assets/img/berita/"+berita.picture} className="img-fluid imageContent" alt="" />
      </div>

    <section id="clients" className="clients section-bg">
      <div className="container" data-aos="fade-up">
        <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up">
          <div className='col-md-10'>
            <h1>{berita.title}</h1>
            <h6>{berita.author}, {moment(berita.date).format('DD MMMM YYYY')}</h6>
            <div className="detailBerita" dangerouslySetInnerHTML={ { __html: berita.desc } }>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
          ))}
  <Footer/>
</>
  );
};

export default DetailBerita;