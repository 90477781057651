import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ExternalLink } from 'react-external-link';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 footer-contact">
              <img className="logo me-auto" src="/assets/img/slide/NBA.png" alt="Logo Footer" width={200} height={60}  />
              <p style={{ marginTop: "20px" }}>
                <strong>{t("alamat.footer")}</strong>
              </p>
              <p>
                Ruko Victorian C4, Sektor 3A, Bintaro, Tangerang Selatan<br></br>
              </p>
              <br></br>
              <strong>{t("kontak.footer")}</strong>
              <br></br>
              <ExternalLink href="tel:+622122971841" target="_blank" >(021) 2297 1841</ExternalLink><br></br>
              <ExternalLink href="mailto:info@nbagroup.co.id" target="_blank" >info@nbagroup.co.id</ExternalLink><br></br>
              <div className="social-links text-md-right">
                {/* <ExternalLink to="#" target="_blank" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </ExternalLink> */}
                <ExternalLink href="https://www.instagram.com/nbaholding/" target="_blank" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </ExternalLink>
                {/* <ExternalLink to="#" target="_blank" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </ExternalLink> */}
                <ExternalLink href="https://www.linkedin.com/company/pt.-nusantara-bina-artha/" target="_blank" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </ExternalLink>
              </div>
              <p><ExternalLink href="https://www.google.com/search?q=nusantara+bina+artha" target="_blank"> {t("cari.footer")} : Nusantara Bina Artha</ExternalLink></p>
            </div>

            <div className="col-lg-4 footer-contact">
              <h5>{t("pilar_usaha.footer")}</h5>
              <Link to="/financial_services" target="_blank">Financial Services</Link><br></br>
              <Link to="/digital_services" target="_blank">Digital Services</Link><br></br>
              <Link to="/professional_services" target="_blank">Professional Services</Link><br></br>
              <Link to="/sme_incubation" target="_blank">SME Incubation</Link><br></br>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="container d-md-flex py-2">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">&copy;2022. All Rights Reserved</div>
        </div>
      {/* <div className="me text-center text-md-right pt-3 pt-md-0" style={{marginRight: "40px"}}>
       <Link to="#">Privacy Policy</Link>    
      </div>
      <div className="me text-center text-md-right pt-3 pt-md-0">
        <Link to="#">Terms of Service</Link>
      </div> */}
      </div>
  </footer>
  );
}
export default Footer;
