import Carousel from "react-bootstrap/Carousel";

function Carousels() {
  return (
    <Carousel variant="dark">
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src="assets/img/Pelita1.png" alt="First slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100 " src="assets/img/Pelita2.png" alt="Second slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src="assets/img/Pelita3.png" alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src="assets/img/Pelita4.png" alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src="assets/img/Pelita5.png" alt="Third slide" />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div >
          <img className="d-block w-100" src="assets/img/Pelita6.png" alt="Third slide" />
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousels;
