import React from 'react';
import { AppContent, AppSidebar, AppFooter, AppHeader, AppSidebarCMO } from './components/index';
import {useState, useEffect} from "react"
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import jwt_decode from "jwt-decode";

function Index() {
import('./scss/style.scss');

    const [role, setRole] = useState('');
    const [expire, setExpire] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
    });

    const refreshToken = async () => {
        try {
            const response = await axios.get('http://api.web-dev.nbagroup.co.id/token');
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                navigate("/login");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get('http://api.web-dev.nbagroup.co.id/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.username);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    let sidebar = "";
    if(role==="Admin") {
      sidebar = <AppSidebar />;
    } else {
      sidebar = <AppSidebarCMO />;
    }

return (
    <div>
      {sidebar}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent /> 
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default Index;
