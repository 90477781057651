import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ExternalLink } from "react-external-link";

const Kaluna = () => {
  
  return (
    <>
      <Header />

      <main id="main">
          <div className="col-md-12 bg-image image">
            <img src="assets/img/kaluna.png" className="img-fluid imageContent" alt="" />
          </div>

        <section id="services" className="services section-bg">
          <div className="container" >
            <div className="section-title" style={{marginTop:"-30px"}}>
              <h2>Kaluna</h2>
            </div>
            <div className="row justify-content-center no-gutters clients-wrap clearfix" data-aos="fade-up" style={{ marginTop: "30px" }}>
              <div className="col-md-10 bg-image ">
                <p style={{ textAlign: "left", marginTop: "-25px", fontWeight: "16px" }}>
                  Kaluna berasal dari Bahasa Sansekerta yang berarti tidak ada duanya, harapan kami Kaluna akan membekas dihati dan akan menjadi kopi satu satunya pilihan masyarakat.
                </p>
                <br />
                <p style={{ textAlign: "left", marginTop: "5px", fontSize: "16px" }}>
                  Kopi kaluna menghadirkan rasa kopi yang cocok untuk lidah Masyarakat Indonesia. Kami nenawarkan citra rasa khas agar kopi Kaluna menjadi pilihan satu - satunya sesuai dengan namanya.
                </p>
                <br />
                <p style={{ textAlign: "left", marginTop: "5px", fontWeight: "16px" }}>
                  Kami terus melakukan inovasi rasa kopi agar sesuai dengan lidah masyrakat Indonesia, dan sajian minuman Non – kopi sebagai alternatif minuman lainnya.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row no-gutters clients-wrap clearfix" data-aos="fade-up">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="assets/img/kaluna1.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="assets/img/kaluna2.png" className="img-fluid center-block" alt="" />
                </div>
                <div className="col-lg-3 bg-image" style={{ marginBottom: "50px", marginLeft: "25px" }}>
                  <img src="assets/img/kaluna3.png" className="img-fluid center-block" alt="" />
                </div>
              </div>
            </div>
            <div class="position-absolute top-100 start-50 translate-middle">
              <ExternalLink href="whatsapp://send?phone=+62885123456789" className="btn" type="button" style={{ backgroundColor: "orange", color: "white" }}>
                Pesan Sekarang
              </ExternalLink>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ marginBottom: "0" }}>
              <h2>Hubungi Kami</h2>
            </div>
              <div className="row justify-content-center align-items-center" >
              <div className="col-md-7">
              <div className="row  justify-content-center" >
                <div className="col-md-2 text-center align-items-center" >
                 <ExternalLink className="instagram">
                 <h5> <i className="bx bxl-facebook"></i> Kaluna </h5>
                </ExternalLink> 
                </div>
                <div className="col-md-2 text-center align-items-center">
                  <ExternalLink href="https://www.instagram.com/kaluna_coffee/" target="_blank" className="instagram">
                 <h5> <i className="bx bxl-instagram"></i> Kaluna </h5>
                </ExternalLink> 
                </div>
                <div className="col-md-3 text-center align-items-center" >
                  <ExternalLink href="whatsapp://send?phone=+62885123456789" target="_blank" className="instagram">
                 <h5> <i className="bx bxl-whatsapp"></i> 085123456789 </h5>
                </ExternalLink> 
                </div>
                <div className="col-md-4"  >
                  <ExternalLink href="https://www.kaluna.co.id/" target="_blank" className="instagram">
                 <h5><i className="bx bx-globe"></i> www.kaluna.co.id</h5>
                </ExternalLink> 
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
export default Kaluna;
